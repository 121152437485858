import React, { useEffect, useState, useContext } from "react";
import firebase from "firebase/app";
import "firebase/messaging";
import Axios from "axios";
import swal from "sweetalert";
import { api, firebaseConfig, header_token, publicVapidKey } from "../config";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { NotiContext } from "./keep";

const GoToPage = (props) => {
  const goTo = (link) => {
    toast.dismiss();
  };

  return <div onClick={() => goTo(props.link)}>{props.text}</div>;
};

export const NotificationFirebaseRestaurant = () => {
  const sw = navigator.serviceWorker;
  const notify = (text, link) =>
    toast.info(<GoToPage text={text} link={link} />);
  const { setnoti } = useContext(NotiContext);
  const [fcmtoken, setfcmtoken] = useState(localStorage.getItem("fcm-token"));
  const [is_register] = useState(localStorage.getItem("register-fcm-token"));

  const getTokenAndSubscribe = async () => {
    if (!firebase.apps.length) {
      firebase.initializeApp(firebaseConfig);
    }
    const messaging = firebase.messaging();

    // messaging.usePublicVapidKey(publicVapidKey);

    if (!("Notification" in window)) {
      alert("This browser does not support desktop notification");
    } else if (Notification.permission === "granted") {
      if (!fcmtoken) {
        messaging.getToken().then(async (token) => {
          // console.log("FCM:", fcmtoken, is_register);

          setfcmtoken(token);
          localStorage.setItem("fcm-token", token);
          await registerToken();
        });
      }
    } else if (Notification.permission !== "denied") {
      Notification.requestPermission().then(function (permission) {
        if (permission === "granted") {
          if (!fcmtoken) {
            messaging.getToken().then(async (token) => {
              setfcmtoken(token);
              localStorage.setItem("fcm-token", token);
              await registerToken();
            });
          }
        }
      });
    }

    messaging.onMessage((body) => {
      let { data } = body;
      // console.log("Message received. ", data);

      let type = data.type;
      let action_type = data.action_type;
      let msg = data.message;

      setnoti((prevState) => ({
        ...prevState,
        getdata: "order",
      }));
    });
  };

  const registerToken = async (token) => {
    if (!is_register && token) {
      try {
        let { data } = await Axios.get(
          `${api}/fcm/register/${token}`,
          header_token
        );
        // console.log("registerToken ok");
        localStorage.setItem("register-fcm-token", true);
      } catch (error) {
        console.log(error);
        swal(`${error.response.data.message}`, {
          icon: "error",
          button: false,
        });
      }
    }
  };

  useEffect(() => {
    // onBackgroundMessage();
  }, []);

  useEffect(() => {
    if (sw) {
      if ("serviceWorker" in navigator) {
        window.addEventListener("load", function () {
          navigator.serviceWorker.register("/firebase-messaging-sw.js").then(
            function (registration) {
              getTokenAndSubscribe();
            },
            function (err) {
              console.log("ServiceWorker registration failed: ", err);
            }
          );
        });
      }
    }
  }, [sw]);

  return (
    <span>
      <ToastContainer
        position="bottom-right"
        autoClose={5000}
        closeOnClick
        pauseOnFocusLoss
        pauseOnHover
      />
    </span>
  );
};
