import React, { useEffect, useState } from "react";

import $ from "jquery";
import dropify from "dropify";
import { useForm } from "react-hook-form";
import Select from "react-select";
import swal from "sweetalert";
import Axios from "axios";
import { api, header_token, image_size, img_url } from "../../config";
import { useHistory, useParams } from "react-router-dom";

const service_type_options = [
  {
    label: "คิดค่าบริการตามระบบ",
    value: "0",
  },
  {
    label: "คิดค่าบริการตามจริง/มิเตอร์/แล้วแต่ตกลงกับผู้ให้บริการ",
    value: 1,
  },
];
const service_status = [
  { label: "ใช้งาน", value: 1 },
  { label: "ระงับ", value: "0" },
];
export const CarServiceEdit = () => {
  const { register, handleSubmit, errors, control, trigger } = useForm();
  const { push } = useHistory();
  const { service_id } = useParams();

  const [data, setdata] = useState({});
  const [service_img, setservice_img] = useState({});
  const [status, setstatus] = useState("");
  const [service_type, setservice_type] = useState("");
  const [addon, setaddon] = useState([]);

  const toBase64 = (file) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });

  const onSubmit = async (value) => {
    // console.log(value);

    try {
      let send_data = {
        area_distance: parseInt(value.area_distance),
        charge_per_kilometer:
          service_type.value === "0" ? parseInt(value.charge_per_kilometer) : 0,
        charge_per_point:
          service_type.value === "0" ? parseInt(value.charge_per_point) : 0,
        detail: value.detail.trim(),
        short_description: value.short_description.trim(),
        name: value.name.trim(),
        short_description_en: value.short_description_en.trim(),
        name_en: value.name_en.trim(),
        percentage_commission_distance:
          service_type.value === "0"
            ? parseInt(value.percentage_commission_distance)
            : 0,
        picture: service_img.base64
          ? service_img.base64
          : service_img.preview
          ? service_img.preview
          : "",
        service_type: parseInt(service_type.value),
        start_fee: service_type.value === 1 ? parseInt(value.start_fee) : 0,
        start_price:
          service_type.value === "0" ? parseInt(value.start_price) : 0,
        status: parseInt(status.value),
      };

      let response = await Axios.put(
        `${api}/admin/car-service/${service_id}`,
        send_data,
        header_token
      );

      // console.log(response);
      if (response.status === 201 || response.status === 200) {
        swal("แก้ไขบริการรถเสร็จสิ้น !!", {
          icon: "success",
          timer: 1000,
        });
        getData();
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getData = async () => {
    let { data } = await Axios.get(
      `${api}/car-service/${service_id}`,
      header_token
    );
    // console.log(data);
    setdata(data);
    setservice_img({
      preview: data.picture,
      base64: "",
    });
    setstatus(
      service_status.filter((e) => parseInt(e.value) === data.status)[0]
    );
    setservice_type(
      service_type_options.filter(
        (e) => parseInt(e.value) === data.service_type
      )[0]
    );

    let service_addon = await Axios.get(
      `${api}/car-service/${service_id}/addon`
    );

    if (service_addon.data) {
      let tmp = [];
      service_addon.data.forEach((value) => {
        tmp.push({
          car_service_id: value.car_service_id,
          id: value.id,
          name: value.name,
          name_en: value.name_en,
          percentage_commission: value.percentage_commission,
          price: value.price,
          edit: false,
          new: false,
        });
      });
      setaddon(tmp);
    } else {
      setaddon([
        {
          id: "",
          name: "",
          price: 0,
          percentage_commission: 0,
          edit: true,
          new: true,
        },
      ]);
    }

    $(".dropify").dropify();

    var drEventRider = $("#carservice-img").dropify();

    drEventRider.on("dropify.afterClear", async function (event, element) {
      await setservice_img({
        preview: "",
        base64: "",
      });
      trigger("service_img");
    });
  };

  const addAddon = async (send_data) => {
    try {
      let res = await Axios.post(
        `${api}/admin/car-service/${service_id}/addon`,
        send_data,
        header_token
      );
      // console.log(res);
      if (res.status === 201) {
        getData();
        return true;
      } else {
        return false;
      }
    } catch (error) {
      console.log(error);
      return false;
    }
  };

  const editAddon = async (addon_id, send_data) => {
    try {
      let res = await Axios.put(
        `${api}/admin/car-service/${service_id}/addon/${addon_id}`,
        send_data,
        header_token
      );
      // console.log(res);
      if (res.status === 201) {
        getData();
        return true;
      } else {
        return false;
      }
    } catch (error) {
      console.log(error);
      return false;
    }
  };

  const deleteAddon = async (addon_id) => {
    try {
      let res = await Axios.delete(
        `${api}/admin/car-service/${service_id}/addon/${addon_id}`,
        header_token
      );

      // console.log(res);
      if (res.status === 200) {
        getData();
        return true;
      } else {
        return false;
      }
    } catch (error) {
      console.log(error);
      return false;
    }
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <div>
      <div className="row mb-4">
        <div className="col-12">
          <div className="card">
            <div className="card-body">
              <form onSubmit={handleSubmit(onSubmit)}>
                <div className="row mb-2">
                  <div className="col-12">
                    <div className="card">
                      <div className="card-header">
                        <h4>แก้ไขบริการรถ</h4>
                      </div>
                      <div className="card-body">
                        <div className="row">
                          <div className="col-12">
                            <div className="form-group row">
                              <div className="col-sm-4">
                                <label htmlFor="name">รูป Icon(150*75)</label>
                              </div>
                              <div className="col-sm-7" id="preview-img-div">
                                <div className="mb-2">
                                  {errors.service_img ? (
                                    <div
                                      className="alert alert-danger"
                                      role="alert"
                                      style={{ width: "100%", padding: 5 }}
                                    >
                                      กรุณาอัพโหลดไฟล์
                                    </div>
                                  ) : (
                                    ""
                                  )}
                                </div>
                                <input
                                  type="file"
                                  id="carservice-img"
                                  className="dropify"
                                  data-max-file-size="3M"
                                  accept=".png, .jpg, .jpeg"
                                  data-default-file={`${img_url}${service_img.preview}`}
                                  onChange={async (e) => {
                                    if (e.target.files.length) {
                                      let img_file = e.target.files[0];
                                      let base64img = await toBase64(img_file);
                                      if (
                                        await image_size(base64img, 150, 75)
                                      ) {
                                        setservice_img({
                                          preview:
                                            URL.createObjectURL(img_file),
                                          base64: base64img,
                                        });
                                        trigger("service_img");
                                      } else {
                                        setTimeout(() => {
                                          $(
                                            "#preview-img-div .dropify-wrapper .dropify-clear"
                                          ).trigger("click");
                                          alert("ขนาดภาพเกินกว่าที่กำหนด");
                                        }, 50);
                                      }
                                    }
                                  }}
                                  ref={(e) => {
                                    register(
                                      { name: "service_img" },
                                      {
                                        validate: (value) => {
                                          return service_img.base64 ||
                                            service_img.preview
                                            ? true
                                            : false;
                                        },
                                      }
                                    );
                                  }}
                                />
                              </div>
                            </div>

                            <div className="form-group row">
                              <label className="col-sm-4 col-form-label">
                                ชื่อ
                              </label>
                              <div className="col-sm-7">
                                <input
                                  id={`name`}
                                  name={`name`}
                                  type="text"
                                  className={`form-control ${
                                    errors.name ? "error-input" : ""
                                  }`}
                                  placeholder="ชื่อ"
                                  ref={register({
                                    required: true,
                                  })}
                                  defaultValue={data.name}
                                />
                              </div>
                            </div>

                            <div className="form-group row">
                              <label className="col-sm-4 col-form-label">
                                ชื่อภาษาอังกฤษ
                              </label>
                              <div className="col-sm-7">
                                <input
                                  id={`name_en`}
                                  name={`name_en`}
                                  type="text"
                                  className={`form-control ${
                                    errors.name_en ? "error-input" : ""
                                  }`}
                                  placeholder="ชื่อภาษาอังกฤษ"
                                  ref={register({
                                    required: true,
                                  })}
                                  defaultValue={data.name_en}
                                />
                              </div>
                            </div>

                            <div className="form-group row">
                              <label className="col-sm-4 col-form-label">
                                รูปแบบการบริการ
                              </label>
                              <div className="col-sm-7">
                                <input
                                  id={`detail`}
                                  name={`detail`}
                                  ref={register({
                                    required: true,
                                  })}
                                  className={`form-control ${
                                    errors.detail ? "error-input" : ""
                                  }`}
                                  type="text"
                                  placeholder="รายละเอียด"
                                  defaultValue={data.detail}
                                />
                              </div>
                            </div>

                            <div className="form-group row">
                              <label className="col-sm-4 col-form-label">
                                คำอธิบายโดยย่อ
                              </label>
                              <div className="col-sm-7">
                                <input
                                  id={`short_description`}
                                  name={`short_description`}
                                  ref={register({
                                    required: true,
                                  })}
                                  className={`form-control ${
                                    errors.short_description
                                      ? "error-input"
                                      : ""
                                  }`}
                                  type="text"
                                  placeholder="รายละเอียด"
                                  defaultValue={data.short_description}
                                />
                              </div>
                            </div>

                            <div className="form-group row">
                              <label className="col-sm-4 col-form-label">
                                คำอธิบายโดยย่อภาษาอังกฤษ
                              </label>
                              <div className="col-sm-7">
                                <input
                                  id={`short_description_en`}
                                  name={`short_description_en`}
                                  ref={register({
                                    required: true,
                                  })}
                                  className={`form-control ${
                                    errors.short_description_en
                                      ? "error-input"
                                      : ""
                                  }`}
                                  type="text"
                                  placeholder="คำอธิบายโดยย่อภาษาอังกฤษ"
                                  defaultValue={data.short_description_en}
                                />
                              </div>
                            </div>

                            <div className="form-group row">
                              <div className="col-sm-4">
                                <label htmlFor="name">สถานะ</label>
                              </div>
                              <div className="col-sm-7">
                                <Select
                                  name="status"
                                  options={service_status}
                                  value={status}
                                  onChange={async (e) => {
                                    await setstatus(e);
                                    trigger("status");
                                  }}
                                  placeholder={"สถานะ..."}
                                  styles={{
                                    control: (base, state) => ({
                                      ...base,
                                      borderColor: errors.status
                                        ? "red"
                                        : "#cdd4e0",
                                      "&:hover": {
                                        borderColor: errors.status
                                          ? "red"
                                          : "#cdd4e0",
                                      },
                                    }),
                                  }}
                                  ref={(e) => {
                                    register(
                                      { name: "status" },
                                      {
                                        validate: () => {
                                          return status.value ? true : false;
                                        },
                                      }
                                    );
                                  }}
                                />
                              </div>
                            </div>

                            {/* <div className=" row">
                              <button
                                className="ml-auto mr-4 btn btn-primary"
                                style={{ width: "15%" }}
                              >
                                บันทึก
                              </button>
                            </div> */}
                          </div>
                        </div>
                      </div>
                      <hr style={{ margin: 0 }} />
                      <div style={{ padding: "1.25rem 1.25rem" }}>
                        <h4>ระยะการให้บริการ</h4>
                      </div>
                      {/* <hr style={{ margin: 0 }} /> */}
                      <div className="card-body">
                        <div className="row">
                          <div className="col-12">
                            <div className="form-group row">
                              <div className="col-sm-4 col-form-label">
                                ระยะทางรับงานครอบคลุม(km)
                              </div>
                              <div className="col-sm-7">
                                <input
                                  type="number"
                                  // placeholder="10"
                                  style={{ width: 100 }}
                                  id={`area_distance`}
                                  name={`area_distance`}
                                  ref={register({
                                    required: true,
                                  })}
                                  className={`form-control ${
                                    errors.area_distance ? "error-input" : ""
                                  }`}
                                  defaultValue={data.area_distance}
                                />
                              </div>
                            </div>
                            {/* <div className=" row">
                              <button
                                className="ml-auto mr-4 btn btn-primary"
                                style={{ width: "15%" }}
                              >
                                บันทึก
                              </button>
                            </div> */}
                          </div>
                        </div>
                      </div>

                      <hr style={{ margin: 0 }} />
                      <div style={{ padding: "1.25rem 1.25rem" }}>
                        <h4>ค่าบริการ</h4>
                      </div>
                      {/* <hr style={{ margin: 0 }} /> */}
                      <div className="card-body">
                        <div className="row">
                          <div className="col-12">
                            <div className="form-group row">
                              <div className="col-sm-4">
                                รูปแบบการคิดว่าบริการ
                              </div>
                              <div className="col-sm-7">
                                <Select
                                  name="service_type"
                                  options={service_type_options}
                                  value={service_type}
                                  onChange={async (e) => {
                                    await setservice_type(e);
                                    trigger("service_type");
                                  }}
                                  placeholder={"รูปแบบ..."}
                                  styles={{
                                    control: (base, state) => ({
                                      ...base,
                                      borderColor: errors.service_type
                                        ? "red"
                                        : "#cdd4e0",
                                      "&:hover": {
                                        borderColor: errors.service_type
                                          ? "red"
                                          : "#cdd4e0",
                                      },
                                    }),
                                  }}
                                  ref={(e) => {
                                    register(
                                      { name: "service_type" },
                                      {
                                        validate: () => {
                                          return service_type.value
                                            ? true
                                            : false;
                                        },
                                      }
                                    );
                                  }}
                                />
                              </div>
                            </div>

                            {service_type.value === "0" ||
                            service_type.value === undefined ? (
                              <div className="form-group row">
                                <div className="col-sm-4">
                                  <span className="">
                                    ค่าบริการเริ่มต้น(บาท)
                                  </span>
                                </div>
                                <div className="col-sm-7">
                                  <input
                                    type="number"
                                    // placeholder="10"
                                    style={{ width: 100 }}
                                    id={`start_price`}
                                    name={`start_price`}
                                    ref={register({
                                      required: true,
                                    })}
                                    className={`form-control ${
                                      errors.start_price ? "error-input" : ""
                                    }`}
                                    defaultValue={data.start_price}
                                  />
                                </div>
                              </div>
                            ) : null}

                            {service_type.value === "0" ||
                            service_type.value === undefined ? (
                              <div className="form-group row">
                                <div className="col-sm-4">
                                  <span className="">
                                    ค่าคอมมิชชั่นตามระยะทาง(%)
                                  </span>
                                </div>
                                <div className="col-sm-7">
                                  <input
                                    type="number"
                                    // placeholder="10"
                                    style={{ width: 100 }}
                                    id={`percentage_commission_distance`}
                                    name={`percentage_commission_distance`}
                                    ref={register({
                                      required: true,
                                    })}
                                    className={`form-control ${
                                      errors.percentage_commission_distance
                                        ? "error-input"
                                        : ""
                                    }`}
                                    defaultValue={
                                      data.percentage_commission_distance
                                    }
                                  />
                                </div>
                              </div>
                            ) : null}

                            {service_type.value === 1 ||
                            service_type.value === undefined ? (
                              <div className="form-group row">
                                <div className="col-sm-4">
                                  <span className="">ค่าเรียก(บาท)</span>
                                </div>
                                <div className="col-sm-7">
                                  <input
                                    type="number"
                                    // placeholder="25"
                                    style={{ width: 100 }}
                                    id={`start_fee`}
                                    name={`start_fee`}
                                    ref={register({
                                      required: true,
                                    })}
                                    className={`form-control ${
                                      errors.start_fee ? "error-input" : ""
                                    }`}
                                    defaultValue={data.start_fee}
                                  />
                                </div>
                              </div>
                            ) : null}

                            {service_type.value === "0" ||
                            service_type.value === undefined ? (
                              <div className="form-group row">
                                <div className="col-sm-4">
                                  <span className="">
                                    ค่าบริการมาตรฐาน/กิโลเมตร(บาท)
                                  </span>
                                </div>
                                <div className="col-sm-7">
                                  <input
                                    type="number"
                                    // placeholder="9"
                                    style={{ width: 100 }}
                                    id={`charge_per_kilometer`}
                                    name={`charge_per_kilometer`}
                                    ref={register({
                                      required: true,
                                    })}
                                    className={`form-control ${
                                      errors.charge_per_kilometer
                                        ? "error-input"
                                        : ""
                                    }`}
                                    defaultValue={data.charge_per_kilometer}
                                  />
                                </div>
                              </div>
                            ) : null}

                            {service_type.value === "0" ||
                            service_type.value === undefined ? (
                              <div className="form-group row">
                                <div className="col-sm-4">
                                  <span className="">
                                    ค่าบริการเพิ่มจุด/จุด(บาท)
                                  </span>
                                </div>
                                <div className="col-sm-7">
                                  <input
                                    type="number"
                                    // placeholder="15"
                                    style={{ width: 100 }}
                                    id={`charge_per_point`}
                                    name={`charge_per_point`}
                                    ref={register({
                                      required: true,
                                    })}
                                    className={`form-control ${
                                      errors.charge_per_point
                                        ? "error-input"
                                        : ""
                                    }`}
                                    defaultValue={data.charge_per_point}
                                  />
                                </div>
                              </div>
                            ) : null}

                            <div className=" row">
                              <button
                                className="ml-auto mr-4 btn btn-primary"
                                style={{ width: "15%" }}
                              >
                                บันทึก
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </form>

              <div className="row mb-4">
                <div className="col-12">
                  <div className="card">
                    <div className="card-header">
                      <h4>บริการเสริม</h4>
                    </div>
                    <div className="card-body">
                      <div className="table-responsive">
                        <div className="content">
                          <table id="example" className="table">
                            <thead>
                              <tr>
                                <th>บริการเสริม</th>
                                <th>ราคา(บาท)</th>
                                <th>หักค่าบริการ(%)</th>
                                <th width="80">Edit</th>
                                <th width="80">Delete</th>
                              </tr>
                            </thead>
                            <tbody>
                              {addon.map((value, index) => {
                                return (
                                  <tr key={index}>
                                    <td className="end">
                                      {value.edit === true ? (
                                        <>
                                          <input
                                            type="text"
                                            className="form-control"
                                            value={value.name}
                                            onChange={(e) => {
                                              let tmp = [...addon];
                                              tmp[index].name = e.target.value;
                                              setaddon(tmp);
                                            }}
                                            placeholder="ชื่อบริการเสริม"
                                          />
                                          <br />
                                          <input
                                            type="text"
                                            className="form-control"
                                            value={value.name_en}
                                            onChange={(e) => {
                                              let tmp = [...addon];
                                              tmp[index].name_en =
                                                e.target.value;
                                              setaddon(tmp);
                                            }}
                                            placeholder="ชื่อบริการเสริมภาษาอังกฤษ"
                                          />
                                        </>
                                      ) : (
                                        <>
                                          {value.name}
                                          <br />
                                          {value.name_en}
                                        </>
                                      )}
                                    </td>
                                    <td className="cost">
                                      {value.edit === true ? (
                                        <input
                                          type="number"
                                          className="form-control"
                                          value={value.price}
                                          onChange={(e) => {
                                            let tmp = [...addon];
                                            tmp[index].price = parseFloat(
                                              e.target.value
                                            );
                                            setaddon(tmp);
                                          }}
                                        />
                                      ) : (
                                        value.price
                                      )}
                                    </td>
                                    <td className="service">
                                      {value.edit === true ? (
                                        <input
                                          type="number"
                                          className="form-control"
                                          value={value.percentage_commission}
                                          onChange={(e) => {
                                            let tmp = [...addon];
                                            tmp[index].percentage_commission =
                                              parseFloat(e.target.value);
                                            setaddon(tmp);
                                          }}
                                        />
                                      ) : (
                                        value.percentage_commission
                                      )}
                                    </td>
                                    <td className="edit">
                                      {value.edit === false ? (
                                        <i
                                          style={{ cursor: "pointer" }}
                                          className="fa tx-20 mt-1 ml-1 text-muted fa-edit"
                                          onClick={() => {
                                            let tmp = [...addon];
                                            tmp[index].edit = !tmp[index].edit;
                                            setaddon(tmp);
                                          }}
                                        ></i>
                                      ) : (
                                        <i
                                          style={{ cursor: "pointer" }}
                                          className="fa tx-20 mt-1 ml-1 text-muted fa-save"
                                          onClick={async () => {
                                            let tmp = [...addon];

                                            if (tmp[index].new === true) {
                                              // console.log("add");
                                              let send_data = {
                                                name: value.name.trim(),
                                                name_en: value.name_en.trim(),
                                                percentage_commission:
                                                  parseFloat(
                                                    value.percentage_commission
                                                  ),
                                                price: parseFloat(value.price),
                                              };
                                              await addAddon(send_data);
                                            } else {
                                              let send_data = {
                                                name: value.name.trim(),
                                                name_en: value.name_en.trim(),
                                                percentage_commission:
                                                  parseFloat(
                                                    value.percentage_commission
                                                  ),
                                                price: parseFloat(value.price),
                                              };
                                              await editAddon(
                                                value.id,
                                                send_data
                                              );
                                            }

                                            // getDataCostDistance();
                                            tmp[index].edit = !tmp[index].edit;
                                            tmp[index].new = false;

                                            setaddon(tmp);
                                          }}
                                        ></i>
                                      )}
                                    </td>

                                    <td className="trash">
                                      <i
                                        style={{ cursor: "pointer" }}
                                        className="fa fa-trash-o tx-20 mt-1 ml-3 text-muted"
                                        onClick={() => {
                                          if (value.edit === false) {
                                            swal({
                                              title: `ต้องการลบหรือไม่ ?`,
                                              // text:
                                              //   "Once deleted, you will not be able to recover this imaginary file!",
                                              confirmButtonText: "ลบ",
                                              cancelButtonText: "ยกเลิก",
                                              icon: "warning",
                                              buttons: true,
                                              dangerMode: true,
                                            }).then(async (willDelete) => {
                                              if (willDelete) {
                                                let is_delete = deleteAddon(
                                                  value.id
                                                );
                                                if (is_delete) {
                                                  swal("ลบเสร็จสิ้น !!", {
                                                    icon: "success",
                                                  });
                                                } else {
                                                  swal("เกิดข้อผิดพลาด !!", {
                                                    icon: "error",
                                                  });
                                                }
                                              }
                                            });
                                          }
                                        }}
                                      ></i>{" "}
                                    </td>
                                  </tr>
                                );
                              })}

                              <tr>
                                <td
                                  className="new-row"
                                  colSpan="7"
                                  onClick={() => {
                                    let tmp = [...addon];
                                    tmp.push({
                                      id: "",
                                      name: "",
                                      name_en: "",
                                      price: 0,
                                      percentage_commission: 0,
                                      edit: true,
                                      new: true,
                                    });
                                    setaddon(tmp);
                                  }}
                                >
                                  + Add
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
